<template>
  <v-card max-width="800" class="mt-5 mx-auto">
    <v-toolbar dense dark color="primary" class="row no-gutters justify-center">
      <v-toolbar-title>{{ isNew() ? "Создать" : "Редактировать" }}</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-3">
      <v-col cols="12" class="col col-lg-6 col-md-6 col-sm-6">
        <v-text-field v-model="item.title"
                      dense
                      outlined
                      hide-details
                      rounded
                      label="Название"/>
      </v-col>
      <v-col cols="12" class="col col-lg-6 col-md-6 col-sm-6">
        <v-text-field v-model="item.description"
                      dense
                      outlined
                      hide-details
                      rounded
                      label="Описание"/>
      </v-col>
      <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3">
        <VueCtkDateTimePicker
            autoClose
            v-model="item.started_at"
            format="YYYY-MM-DDTHH:mm:ssZ"
            formatted="YYYY-MM-DD"
            locale="ru"
            label="Начало"
            id="item.started_at"
            color="#3FC44F"
            button-color="#3FC44F"
            :onlyDate="t"/>
      </v-col>
      <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3">
        <VueCtkDateTimePicker
            autoClose
            v-model="item.ended_at"
            format="YYYY-MM-DDTHH:mm:ssZ"
            formatted="YYYY-MM-DD"
            locale="ru"
            label="Завершение"
            id="item.ended_at"
            color="#3FC44F"
            button-color="#3FC44F"
            :onlyDate="t"/>
      </v-col>
      <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3">
        <v-text-field v-model="item.amount"
                      dense
                      outlined
                      hide-details
                      rounded
                      label="Скидка на %"/>
      </v-col>
      <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-center">
        <v-switch v-model="item.our"
                  inset
                  color="primary"
                  class="pa-0 mt-2"
                  hide-details
                  label="От нас"/>
      </v-col>
      <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3">
        <v-text-field
            v-model="item.begin_at"
            hide-details
            dense
            outlined
            rounded
            label="Начало"/>
      </v-col>
      <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3">
        <v-text-field
            v-model="item.finish_at"
            hide-details
            dense
            outlined
            rounded
            label="Завершение"/>
      </v-col>
      <v-col v-if="!item.our" cols="12" class="col col-lg-6 col-md-6 col-sm-6">
        <v-autocomplete
            v-model="item.store"
            :items="stores"
            item-text="name"
            item-value="id"
            :filter="filter"
            label="Кафе"
            outlined
            rounded
            dense
            hide-details
            return-object
            @change="fetchItems(item.store.id); fetchCategories(item.store.id);"/>
      </v-col>
      <v-col v-if="!item.our" cols="12" class="col col-lg-6 col-md-6 col-sm-6">
        <v-autocomplete
            v-model="item.item"
            :items="items"
            item-text="name"
            item-value="id"
            :filter="filter"
            label="Блюдо"
            outlined
            rounded
            dense
            hide-details
            return-object/>
      </v-col>
      <v-col v-if="!item.our" cols="12" class="col col-lg-6 col-md-6 col-sm-6">
        <v-autocomplete
            v-model="item.category"
            :items="categories"
            item-text="name"
            item-value="id"
            :filter="filter"
            label="Категория"
            outlined
            rounded
            dense
            hide-details
            return-object/>
      </v-col>
      <v-col cols="12" class="row no-gutters justify-center">
        <v-chip-group multiple column active-class="primary" v-model="item.weekdays">
          <v-chip class="mx-3" v-for="day in weekdays" :key="day" small color="grey lighten-1">
            {{ day }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row class="justify-space-around pb-3">
      <v-btn outlined elevation="3" rounded color="error" @click="close">Отменить</v-btn>
      <v-btn outlined elevation="3" rounded color="primary" @click="save">Сохранить</v-btn>
    </v-row>
  </v-card>
</template>

<script>
import axios from "../../services/axios";
import {uuid} from "uuidv4";

export default {
  data() {
    return {
      t: true,
      item: {
        id: uuid.Nil,
        description: "",
        amount: 0,
        started_at: "",
        ended_at: "",
        our: false,
        promo_code: "",
        store: {id: uuid.Nil, name: ""}
      },
      categories: [],
      stores: [],
      items: [],
      timePickerProps: {
        format: "24hr",
      },
      menuBegin: false,
      menuFinish: false,
      weekdays: [
        "Воскресенье",
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота",
      ],
    }
  },

  created() {
    this.fetchStores();
    this.fetch();
  },

  methods: {
    filter(item, queryText) {
      const text = item.name.toLowerCase()
      const searchText = queryText.toLowerCase()
      return text.indexOf(searchText) > -1
    },
    isNew() {
      if (!this.item.id) {
        return true
      }
      return !this.item.id === uuid.Nil;
    },

    fetch() {
      let id = this.$route.params.id;
      if (id) {
        axios.send({
          url: "/discounts/" + id,
          method: "GET",
        }).then((resp) => {
          this.item = resp.data;
          if (this.item.store && this.item.store.id) {
            this.fetchItems(this.item.store.id);
            this.fetchCategories(this.item.store.id);
          }
        })
      }
    },

    fetchStores() {
      axios.send({
        url: "/store/active",
        method: "GET"
      }).then(resp => {
        this.stores = resp.data;
      })
    },

    fetchItems(id) {
      axios({
        url: "/items/" + id,
        method: "GET",
      }).then((resp) => {
        this.items = resp.data;
        this.items.unshift({id: uuid.Nil, name: ""});
      })
    },

    fetchCategories(id) {
      axios.send({
        url: "/categories/store/" + id,
        method: "GET",
      }).then((resp) => {
        this.categories = resp.data;
        this.categories.unshift({id: uuid.Nil, name: ""});
      })
    },

    save() {
      this.item.amount = parseInt(this.item.amount);
      axios.send({
        url: "/discounts",
        method: "POST",
        data: this.item,
      }).then(() => {
        this.close();
      })
    },

    close() {
      this.item = {
        id: uuid.Nil,
        name: "",
        description: "",
        amount: 0,
        started_at: "",
        ended_at: "",
        our: false,
        promo_code: "",
        store: {id: uuid.Nil, name: ""}
      }
      this.$router.push({name: "discounts"});
    }
  }
}
</script>
